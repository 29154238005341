import "../styles/globals.css";
import type { AppProps } from "next/app";
import "@dreambigger/design-system/src/styles/index.scss";
import "../styles/tiptap.scss";
import "../styles/antd.scss";
import "@dreambigger/design-system/src/styles/utilities/_display.scss";
import "@dreambigger/design-system/src/styles/utilities/_flexbox.scss";
import "@dreambigger/design-system/src/styles/utilities/_typography.scss";

function MyApp({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} />;
}
export default MyApp;
